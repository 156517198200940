import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function Mural() {
  return (
    <div id="mural" className="flex flex-wrap flex-row gap-6 ml-8 max-w-screen-xl w-full ml-auto mr-auto">
      <div className="flex flex-col w-full">
        <div className="text-5xl font-bold py-8 text-center px-4">
          Mural de Avisos
        </div>

        <div className="flex flex-row gap-5 justify-center px-4">
          <div className="flex flex-col text-center">
            <a
              href="mailto:admcondominiofit@gmail.com"
              target="_blank"
              rel="noreferrer"
              class="inline-flex text-center px-6 py-2 mt-0 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              Duvidas sobre algum aviso? Envie um e-mail para o Sindico.
              <EmailIcon />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5511999709020"
              target="_blank"
              rel="noreferrer"
              class="inline-flex text-center px-6 py-2 mt-1 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              Duvidas sobre o site? Envie uma mensagem ao desenvolvedor.
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col text-center"></div>
      <div className="mt-0">
        <ol class="flex flex-row m-0 gap-8 flex-wrap justify-center" >

          <li class="mb-0 h-full max-w-smx border rounded-2xl border-sky-700 bg-[#0b212d] p-4">
            <h3 class="flex items-center mb-1 text-lg font-semibold  text-white justify-center mb-3">
              Sorteio de Vagas
            </h3>
            <time class="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
              Local: Salão de Festas
            </time>
            <p class="mb-4 text-base font-normal  text-gray-400">
              No proximo dia 28/09 haverá o sorteio anual de vagas! 
              A primera chamada ocorrerá as 09hrs dando início as 09h30m.
              Neste dia teremos a única pauta de sorteio e nenhum outro assunto.
              Pedimos a gentileza que os proprietários com vagas retirem na portaria o edital para mais detalhes.
            </p>
            <article className="contact-option">
                <h4 className="mb-2">Att.</h4>
                <h5>Sindico</h5>
                <section className="flex flex-row justify-between mt-3">
                  <a
                    href="mailto:admcondominiofit@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
                  >
                    <svg
                      class="w1 h1 mr1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                    </svg>{" "}
                    Email <EmailIcon/>
                  </a><br/>
                </section>
                <section className="flex flex-row justify-between mt-3">
                  <a
                    href={require("../../assets/files/edital.pdf")}
                    download={"Edital_Sorteio_de_Vagas"}
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
                  >
                    <svg
                      class="w1 h1 mr1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                    </svg>{" "}
                     Edital <CloudDownloadIcon/>
                  </a><br/>
                </section>
              </article>
          </li>

          <li class="mb-0 h-full max-w-smx border rounded-2xl border-sky-700 bg-[#0b212d] p-4">
            <h3 class="flex items-center mb-1 text-lg font-semibold  text-white justify-center mb-3">
              Barulho nos Apartamentos
            </h3>
            <time class="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
            Proibido de 2ª a 6ª das 22hrs as 08hrs - Aos finais de semana das 22hrs as 09hrs.
            </time>
            <p class="mb-4 text-base font-normal  text-gray-400">
              Utilizar som audível que o apartamento do vizinho possa ouvir de aparelho musical, 
              TV, promover festas ou reuniões onde são siscetíveis de prejudicar ou pertubar 
              o sossego dos demais moradores esta sujeito a advertencia e multa.
            </p>
            <article className="contact-option">
                <h4 className="mb-2">Regulamento Interno - CAP.I</h4>
                <h5>ART. 6º - Item 6.6</h5>
                <section className="flex flex-row justify-between mt-3">
                  <a
                    href="mailto:admcondominiofit@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
                  >
                    <svg
                      class="w1 h1 mr1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                    </svg>{" "}
                    Sindico <EmailIcon/>
                  </a><br/>
                </section>
              </article>
          </li>

          <li class="mb-0 h-full max-w-smx border rounded-2xl border-sky-700 bg-[#0b212d] p-4">
            <h3 class="flex items-center mb-1 text-lg font-semibold  text-white justify-center mb-3">
              Animais De Estimação
            </h3>
            <time class="block pt-1 pb-3  text-sm font-normal leading-none  text-gray-500">
            Proibido a Circulção de Pets no Hall e Áreas Comuns
            </time>
            <p class="mb-4 text-base font-normal  text-gray-400">
              Tenha somente pets de pequeno porte, pois eles não podem circular pelo hall 
              e áreas comuns, a circulação somente é permitida para entrada e saída. Seja educado,
                não deixe seu pet solto e não permita que os mesmos façam suas necessidades
                fisiológicas no condomínio, pois além de estarem sujeitos a multa atrai moscas,
                 baratas, ratos e etc.
            </p>
            <article className="contact-option">
                <h4 className="mb-2">Regulamento Interno CAP.VII - ARTs. 1º/2º</h4>
                <h5>ART 3. - Multa 5x o valor do Condomínio Vigente</h5>
                <section className="flex flex-row justify-between mt-3">
                  <a
                    href="mailto:admcondominiofit@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    class="inline-flex items-center px-4 py-2 mt-2 text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
                  >
                    <svg
                      class="w1 h1 mr1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                    </svg>{" "}
                    Sindico <EmailIcon/>
                  </a><br/>
                </section>
              </article>
          </li>

        </ol>
        <div className="flex flex-row gap-5 justify-center px-4">          
          <div className="flex flex-col text-center">
            <a
              href="mailto:admcondominiofit@gmail.com"
              target="_blank"
              rel="noreferrer"
              class="inline-flex text-center px-6 py-2 mt-12 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              Duvidas sobre algum aviso? Envie um e-mail para o Sindico.
              <EmailIcon />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5511999709020"
              target="_blank"
              rel="noreferrer"
              class="inline-flex text-center px-6 py-2 mt-1 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
            >
              Duvidas sobre o sitea? Envie uma mensagem ao desenvolvedor.
              <WhatsAppIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}